<template>
    <div class="ivv-content">
        <div ref="content" :style="`transform: scale(1.0, 0.4);position: relative;margin-top: -${bottom}px`">
            <div class="theme">
                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315153911707.png"/>
                <div class="auth-logo">
                    <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315175014081.png"/>
                </div>
            </div>
            <van-list class="rank-list" v-model="loading" :finished="finished" finished-text="" @load="onLoad">
                <div class="rank-item">
                    <div class="ivv-title">
                        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315180331019.png"/>
                    </div>
                    <div class="ivv-list">
                        <van-row class="ivv-header">
                            <van-col span="4"></van-col>
                            <van-col span="2"></van-col>
                            <van-col span="5">姓名</van-col>
                            <van-col span="6">订货业绩</van-col>
                            <van-col span="7">排名情况</van-col>
                        </van-row>
                        <van-row class="ivv-user" v-for="(item,index) in dataList" :key="index">
                            <van-col class="rank-num" span="3">
                                <img v-if="index === 0"
                                     src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315155924144.png"/>
                                <img v-else-if="index === 1"
                                     src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315160051738.png"/>
                                <img v-else-if="index === 2"
                                     src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315160103097.png"/>
                                <div class="ivv-serial" v-else>
                                    <span>No.</span>
                                    <div>{{index + 1}}</div>
                                </div>
                            </van-col>
                            <van-col class="head-img" span="3">
                                <img :src="item.headImg"/>
                            </van-col>
                            <van-col span="5">
                                <div class="user-name ellipsis2">{{item.name}}</div>
                                <div class="f-code">{{item.authCode}}</div>
                            </van-col>
                            <van-col class="performance" span="6">￥{{item.perf}}</van-col>
                            <van-col class="ivv-reward ellipsis2" :class="{'horizontal':item.upOrDown === 0}" span="7">
                                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210323164100130.png"
                                     v-if="item.upOrDown === 1"/>
                                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210323164118801.png"
                                     v-else-if="item.upOrDown === 2"/>
                                <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20210324114646176.png" v-else/>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
</template>

<script>
  export default {
    name: "SilverStarList",
    data() {
      return {
        loading: false,
        finished: false,
        dataList: [],
        pg: 1,
        ps: 1000,
        bottom: 0
      }
    },
    created() {
      setInterval(() => {
        this.onLoad();
      }, 30000)
    },
    mounted() {
      document.documentElement.style.fontSize = 'calc(100vw / 1488 * 100)';
    },
    methods: {
      onLoad() {
        // this.$toast.loading({message: '加载中...', overlay: true, duration: 0});
        this.get("/OnlyOnce/OfflinePKCompetition/OverallGradeList?", {
          star: 2,
          page: this.pg,
          length: this.ps
        }, 1).then(res => {
          if (res.code === 1) {
            let list = res.response;
            this.dataList = list;
            if (list.length < this.ps) {
              this.finished = true;
            }
            this.finished = true
              setTimeout(() => {
                  const dom = this.$refs.content
                  this.bottom = dom.clientHeight *0.6/2
              }, 200)
          }
          // this.$toast.clear();
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
    .ivv-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: 100%;
        font-size: 4.26vw;
        overflow-y: auto;
        padding: 0 1.1rem;
        /*background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315155146191.png") no-repeat #b21e22;*/
        /*background-size: 100% auto;*/
        background-color: rgb(178,30,34);

        .theme {
            position: relative;
            padding: 0.6rem 0;

            > img {
                width: 2.81rem;
            }

            .auth-logo {
                position: absolute;
                right: 0;
                bottom: 0;

                img {
                    width: 4.13rem;
                }
            }
        }


        .rank-list {
            .rank-item {
                margin-top: 0.7rem;
                border-radius: 0.12rem;
                background-color: #fff;

                .ivv-title {
                    height: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: url("http://freesaasofficedata.oss.aliyuncs.com/miShop/20210315160703832.png") no-repeat;
                    background-size: 100% 110%;

                    img {
                        width: 2.46rem;
                    }
                }

                .ivv-list {
                    padding: 0 0.56rem;

                    .ivv-header {
                        line-height: 0.7rem;

                        .van-col {
                            font-size: 0.3rem;
                        }
                    }

                    .ivv-user {
                        display: flex;
                        align-items: center;
                        /*padding: 0.1rem 0;*/
                        border-bottom: 0.01rem solid #f1f1f1;

                        .rank-num {
                            > img {
                                width: 0.56rem;
                            }

                            .ivv-serial {
                                span {
                                    color: #fff;
                                    padding: 0.02rem 0.1rem;
                                    font-size: 0.1945rem;
                                    border-radius: 0.19rem;
                                    background-color: #cccccc;
                                }

                                div {
                                    font-size: 0.3889rem;
                                }
                            }
                        }

                        .head-img {
                            font-size: 0;

                            img {
                                width: 0.73rem;
                                height: 0.73rem;
                                border-radius: 50%;
                            }
                        }

                        .user-name {
                            color: #000;
                            font-size: 0.28rem;
                        }

                        .f-code {
                            color: #999;
                            font-size: 0.24rem;
                            padding-top: 0.1rem;
                        }

                        .performance {
                            color: #fd0303;
                            font-size: 0.409rem;
                        }

                        .ivv-reward {
                            color: #f9b022;

                            img {
                                width: 0.3rem;
                            }
                            &.horizontal {
                                img {
                                    width: 0.9rem;
                                }
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }

                    .ivv-total {
                        color: #666;
                        padding: 0.3rem 0;
                        font-size: 0.4rem;

                        span {
                            color: #000;
                        }

                        .font-yellow {
                            color: #f9b022;
                        }
                    }
                }


                &:first-child {
                    margin-top: 0;
                }
            }

            /deep/ .van-list__finished-text {
                color: #fff;
            }
        }
    }

</style>