import { render, staticRenderFns } from "./SilverStarList.vue?vue&type=template&id=474e03df&scoped=true&"
import script from "./SilverStarList.vue?vue&type=script&lang=js&"
export * from "./SilverStarList.vue?vue&type=script&lang=js&"
import style0 from "./SilverStarList.vue?vue&type=style&index=0&id=474e03df&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474e03df",
  null
  
)

export default component.exports